import { render, staticRenderFns } from "./BeforeStart.vue?vue&type=template&id=0ec8410f&"
import script from "./BeforeStart.vue?vue&type=script&lang=js&"
export * from "./BeforeStart.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports