<template>
  <div class="border-radius p-2">
    <div class="subTitle bold">
      {{ $t("result_subject_recommand_product") }}
    </div>
    <div style="display: flex; justify-content: center; flex-wrap: wrap">
      <div v-for="recommand in recommands" :key="recommand.id" class="m-2">
        <a :href="recommand.link" target="_blank" class="colorMain">
          <img
            :src="recommand.image"
            style="max-height: 200px; width: auto !important"
          />
          <div style="text-align: center">{{ recommand.name }}</div>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    record: {
      type: Object,
      default: null,
    },
  },
  computed: {
    recommands() {
      const _recommands = [];
      const source = this.$route.params.source;
      // 新增廠商產品
      _recommands.push(
        ...this.record.recommand.filter(
          (recommand) => recommand.source == source
        )
      );
      // 新增官方產品
      _recommands.push(
        ...this.record.recommand.filter(
          (recommand, index) => recommand.source == null
        )
      );
      // 新增其餘產品
      _recommands.push(
        ...this.record.recommand.filter(
          (recommand, index) =>
            recommand.source != source && recommand.source != null
        )
      );
      return _recommands;
    },
  },
  methods: {
    getContent(content) {
      if (content.indexOf("http") == 0) {
        return `<a href="${content}" target="_blank">${content}</a>`;
      }
      return content;
    },
  },
};
</script>