<template>
  <div style="width: 100%">
    <div class="row mx-0">
      <div
        class="px-0 field"
        :style="{
          width: labelWidth,
          display: 'flex',
        }"
      >
        <span :class="requiredClass" style="margin: auto 0px">{{ field }}</span>
      </div>
      <div
        class="px-0"
        :style="{
          margin: 'auto',
          'text-align': contentAlign,
          width: `calc( 100% - ${labelWidth} )`,
        }"
      >
        <slot name="content" />
      </div>
    </div>
    <div
      v-show="message"
      :class="messageColor + ' content'"
      :style="`padding-left: ${labelWidth}`"
    >
      {{ message }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    contentAlign: {
      type: String,
      default: "right",
    },
    labelWidth: {
      type: String,
      default: "100px",
    },
    field: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: "",
    },
    messageColor: {
      type: String,
      default: "colorRed",
    },
    beforeOpacity: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {},
  computed: {
    requiredClass() {
      return this.required
        ? "required"
        : this.beforeOpacity
        ? "required beforeOpacity"
        : "";
    },
  },
  methods: {},
};
</script>
