<template>
 <!-- 開始測驗前頁面 -->
  <div>
    <div class="px-3 py-5">
      <div style="white-space: pre-wrap">{{ $t("before_start_content") }}</div>
      <div class="row justify-content-center" style="margin: 50px 0">
        <img src="/img/write.png" style="width: 40vmin; max-width: 150px" />
      </div>
    </div>

    <div class="button1" @click="goNext()">
      {{ $t("before_start_button_start") }}
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.basicInfo = this.$store.state.basicInfo;
  },
  data() {
    return {
      basicInfo: null,
    };
  },
  methods: {
    goNext() {
      setCookie("answers", "");
      const basicInfo = this.basicInfo;
      this.$router.replace({
        name: "BasicInfo"
      });
    },
  },
};
</script>