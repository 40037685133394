import { ApiMethods } from "../enum/ApiMethods";
import ApiHelper from "./ApiHelper";

const api = {
    "answer": "/api/Answer",
    "records": "/api/Records",
    "basicInfo": "/api/BasicInfo",
    "question": "/api/Question",
    "userData": "/api/UserData",
    "logout": "/api/Logout",
    "extraInfo": "/api/ExtraInfo",
    "canTest": "/api/CanTest"
}



export default class LoginApiHelper {

    public static submitBasicInfo(params: Object) {
        return ApiHelper.callSelf(ApiMethods.POST, api.basicInfo, params);
    }
    public static modifyBasicInfo(params: Object) {
        return ApiHelper.callSelf(ApiMethods.PUT, api.basicInfo, params);
    }
    public static submitAnswer(params: Object) {
        return ApiHelper.callSelf(ApiMethods.POST, api.answer, params);
    }
    public static getRecords(params: Object) {
        return ApiHelper.callSelf(ApiMethods.GET, api.records, params);
    }
    public static getBasicInfo(params: Object) {
        return ApiHelper.callSelf(ApiMethods.GET, api.basicInfo, params);
    }
    public static getUserData(params: Object) {
        return ApiHelper.callSelf(ApiMethods.GET, api.userData, params);
    }
    public static getQuestion(params: Object) {
        return ApiHelper.callSelf(ApiMethods.GET, api.question, params);
    }
    public static getExtraInfo(params: Object) {
        return ApiHelper.callSelf(ApiMethods.GET, api.extraInfo, params);
    }
    public static canTest(source) {
        return ApiHelper.callSelf(ApiMethods.GET, api.canTest, { source });
    }
    public static logout() {
        return ApiHelper.callSelf(ApiMethods.POST, api.logout);
    }
}