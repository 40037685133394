import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex);
export default new Vuex.Store({
    getters: {
        basicInfo: state => state.basicInfo,
    },
    state() {
        return {
            basicInfo: null
        }
        
    },
    mutations: {
        setBasicInfo(state, item) {
            Vue.set(state, "basicInfo", item);
        },
    }
})