var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100%"}},[_c('div',{staticClass:"row mx-0"},[_c('div',{staticClass:"px-0 field",style:({
        width: _vm.labelWidth,
        display: 'flex',
      })},[_c('span',{class:_vm.requiredClass,staticStyle:{"margin":"auto 0px"}},[_vm._v(_vm._s(_vm.field))])]),_vm._v(" "),_c('div',{staticClass:"px-0",style:({
        margin: 'auto',
        'text-align': _vm.contentAlign,
        width: ("calc( 100% - " + _vm.labelWidth + " )"),
      })},[_vm._t("content")],2)]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.message),expression:"message"}],class:_vm.messageColor + ' content',style:(("padding-left: " + _vm.labelWidth))},[_vm._v("\n    "+_vm._s(_vm.message)+"\n  ")])])}
var staticRenderFns = []

export { render, staticRenderFns }