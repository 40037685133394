<template>
  <!-- 輸入基本資料頁面 -->
  <div>
    <div style="position: relative; background-size: cover; position: relative">
      <div class="py-4 colorBlack" style="text-align: center">
        <div>
          {{ $t("basic_info_hint")
          }}<span
            class="circleLabelNoFill borderWarn"
            style="display: inline-block"
            >!</span
          >
        </div>
        <div>{{ $t("basic_info_hint_content") }}</div>
      </div>
      <div class="inputSolid bold radius-5">
        <field-edit
          class="my-4"
          :field="$t('basic_info_field_name')"
          v-model="basicInfo.name"
          :placeholder="$t('basic_info_field_name_hint')"
          :maxlength="10"
          :labelWidth="labelWidth"
          :required="true"
          name="name"
        />
        <FieldItem
          v-if="
            rawBasicInfo &&
            (rawBasicInfo.is_overseas === null ||
              (rawBasicInfo.is_overseas === 1 && !rawBasicInfo.id_number))
          "
          class="my-4"
          :field="
            basicInfo.is_overseas
              ? $t('basic_info_field_id_passport')
              : $t('basic_info_field_id_number')
          "
          :labelWidth="labelWidth"
          :required="!basicInfo.is_overseas"
          :beforeOpacity="true"
        >
          <div slot="content" style="display: flex; flex-flow: wrap">
            <Input
              v-model="basicInfo.id_number"
              :placeholder="
                basicInfo.is_overseas
                  ? $t('basic_info_field_id_passport_hint')
                  : $t('basic_info_field_id_number_hint')
              "
              :maxlength="basicInfo.is_overseas ? 20 : 10"
              name="id_number"
            />
            <Checkbox
              v-model="basicInfo.is_overseas"
              :true-value="1"
              :false-value="0"
              >{{ $t("basic_info_field_is_overseas") }}</Checkbox
            >
          </div>
        </FieldItem>
        <field-edit
          class="my-4"
          field="E-Mail"
          v-model="basicInfo.email"
          placeholder="service@louyiai.com"
          :maxlength="50"
          :labelWidth="labelWidth"
          name="email"
        />
        <field-radio
          class="my-4"
          :field="$t('basic_info_field_gender')"
          v-model="basicInfo.gender"
          group="gender"
          :labelWidth="labelWidth"
          :required="true"
          :selections="[
            { tag: 0, text: $t('basic_info_field_gender_0') },
            { tag: 1, text: $t('basic_info_field_gender_1') },
          ]"
        />
        <div class="row my-4 mx-0">
          <div
            class="mx-0 field"
            :style="{
              margin: 'auto',
              position: 'relative',
              width: labelWidth,
            }"
          >
            <span class="required" style="margin: auto 0px">{{
              $t("basic_info_field_blood_type")
            }}</span>
          </div>
          <div
            class="mx-0 row align-items-center"
            :style="{
              width: `calc(100% - ${labelWidth})`,
              height: '32px',
            }"
          >
            <field-select
              class="px-0"
              :selections="[
                { value: '-1', text: $t('basic_info_field_select') },
                { value: 'A', text: $t('basic_info_field_blood_type_A') },
                { value: 'B', text: $t('basic_info_field_blood_type_B') },
                { value: 'O', text: $t('basic_info_field_blood_type_O') },
                { value: 'AB', text: $t('basic_info_field_blood_type_AB') },
                { value: 'NA', text: $t('basic_info_field_unknow') },
              ]"
              v-model="basicInfo.blood_type"
              field=""
              labelWidth="0px"
            />
          </div>
        </div>

        <div>
          <field-radio
            class="my-4"
            :field="$t('basic_info_field_birth_date')"
            v-model="birth_type"
            group="birth_type"
            :labelWidth="labelWidth"
            :required="true"
            :selections="[]"
          />
          <div class="row my-4 mx-0 align-items-center">
            <div
              class="required"
              :style="{ width: labelWidth }"
              v-html="$t('basic_info_field_birth_type_solor')"
            ></div>
            <div
              class="mx-0 row align-items-center h-100"
              :style="`width: ${valueWidth}; height: 32px !important`"
            >
              <field-select
                class="px-0 w-0"
                :selections="years"
                v-model="basicInfo.birth.year"
                field=""
                labelWidth="0px"
                style="flex-grow: 2"
              />
              <field-select
                class="px-0 pl-1 w-0"
                :selections="months"
                v-model="basicInfo.birth.month"
                field=""
                labelWidth="0px"
                style="flex-grow: 1"
              />
              <field-select
                class="px-0 pl-1 w-0"
                :selections="datesSolar"
                v-model="basicInfo.birth.date"
                field=""
                labelWidth="0px"
                style="flex-grow: 1"
              />
            </div>
            <div
              class="colorGreen button clickScale pt-2"
              @click="resetSolarDate()"
              :style="`text-decoration-line: underline; padding-left: ${labelWidth}`"
            >
              {{ $t("basic_info_field_lunar_to_solor") }}
            </div>
          </div>

          <div class="row my-4 mx-0 align-items-center">
            <div
              class="required"
              :style="{ width: labelWidth }"
              v-html="$t('basic_info_field_birth_type_lunar')"
            ></div>
            <div
              class="mx-0 row align-items-center h-100"
              :style="`width: ${valueWidth}; height: 32px !important`"
            >
              <field-select
                class="px-0 w-0"
                :selections="years"
                v-model="basicInfo.birth.lyear"
                field=""
                labelWidth="0px"
                style="flex-grow: 2"
              />
              <field-select
                class="px-0 pl-1 w-0"
                :selections="months"
                v-model="basicInfo.birth.lmonth"
                field=""
                labelWidth="0px"
                style="flex-grow: 1"
              />
              <field-select
                class="px-0 pl-1 w-0"
                :selections="datesLunar"
                v-model="basicInfo.birth.ldate"
                field=""
                labelWidth="0px"
                style="flex-grow: 1"
              />
            </div>
            <checkbox-button
              v-model="basicInfo.birth.isLeap"
              tag="isLeap"
              :text="$t('basic_info_field_isleap')"
              :style="`padding-left:${labelWidth};width: ${valueWidth};`"
              :disabled="!enabledIsLeap"
            />
            <div
              class="colorGreen button clickScale pt-2"
              @click="resetLunarDate()"
              :style="`text-decoration-line: underline; padding-left: ${labelWidth}`"
            >
              {{ $t("basic_info_field_solor_to_lunar") }}
            </div>
          </div>
        </div>
        <div class="row my-4 mx-0">
          <div
            class="mx-0 field"
            :style="{
              margin: 'auto',
              position: 'relative',
              width: labelWidth,
            }"
          >
            <span
              class="required"
              style="margin: auto 0px"
              v-html="$t('basic_info_field_birth_hour')"
            >
            </span>
          </div>
          <div
            class="mx-0 row align-items-center"
            :style="{
              width: `calc(100% - ${labelWidth})`,
              height: '32px',
            }"
          >
            <field-select
              class="px-0"
              :selections="hours"
              v-model="basicInfo.birth.hour"
              field=""
              labelWidth="0px"
            />
          </div>
        </div>
        <div v-if="showConstitution" class="row my-4 mx-0">
          <div
            class="mx-0 field"
            :style="{
              margin: 'auto',
              position: 'relative',
              width: labelWidth,
            }"
          >
            <span
              class="required beforeOpacity"
              style="margin: auto 0px"
              v-html="$t('basic_info_field_constitution')"
            >
            </span>
          </div>
          <div
            class="mx-0 align-items-center"
            :style="{
              width: `calc(100% - ${labelWidth})`,
              height: '32px',
            }"
          >
            <field-select
              class="px-0"
              :selections="constitutions"
              v-model="basicInfo.constitution_code"
              field=""
              labelWidth="0px"
            />
            <div style="color: red">如選擇此項目將跳過檢測問券</div>
          </div>
        </div>
      </div>
      <div class="button1 my-5" @click="goNext()">
        {{ $t("basic_info_button_next") }}
      </div>
    </div>
  </div>
</template>
<script>
import solarLunar from "solarlunar";
import FieldEdit from "../component/FieldEdit.vue";
import RadioButton from "../component/RadioButton.vue";
import UserInfoApiHelper from "../../script/Utility/UserInfoApiHelper";
import FieldRadio from "../component/FieldRadio.vue";
import CheckoutItem from "../component/CheckoutItem.vue";
import CheckboxButton from "../component/CheckboxButton.vue";
export default {
  components: {
    FieldEdit,
    RadioButton,
    FieldRadio,
    CheckoutItem,
    CheckboxButton,
  },
  mounted() {
    window.solarLunar = solarLunar;
    this.$root.$on("enter", this.goNext);
    const basicInfo = this.$store.state.basicInfo;
    if (basicInfo) {
      this.setBasicInfo(copy(basicInfo));
    }
  },
  beforeDestroy() {
    this.$root.$off("enter", this.goNext);
  },
  data() {
    const labelWidth = "100px";

    return {
      registed: true,
      birth_type: "solar",
      labelWidth: labelWidth,
      valueWidth: `calc(100% - ${labelWidth})`,
      rawBasicInfo: {},
      basicInfo: {
        name: "",
        gender: -1,
        blood_type: "-1",
        email: "",
        constitution_code: "-1",
        birth: {
          year: "-1",
          month: "-1",
          date: "-1",
          lyear: "-1",
          lmonth: "-1",
          ldate: "-1",
          hour: "-1",
          isLeap: [],
        },
        trial: false,
        is_overseas: false,
        id_number: "",
      },
      startYear: new Date().getFullYear() - 100,
      endYear: new Date().getFullYear() - 10,
    };
  },
  computed: {
    showConstitution() {
      if (this.basicInfo.trial) {
        return true;
      }
      return false;
    },
    years() {
      const allYears = [
        { text: this.$t("basic_info_field_select_year"), value: "-1" },
      ];
      for (let i = this.endYear; i > this.startYear; i--) {
        const text = `${i}(${this.$t("basic_info_field_select_tw", [
          i - 1911,
        ])})`;
        allYears.push({ value: i, text });
      }
      return allYears;
    },
    months() {
      const allMonth = [
        { text: this.$t("basic_info_field_select_month"), value: "-1" },
      ];
      for (let i = 1; i <= 12; i++) {
        const text = `${i}`;
        allMonth.push({ value: i, text });
      }
      return allMonth;
    },
    datesSolar() {
      const allDate = [
        { text: this.$t("basic_info_field_select_date"), value: "-1" },
      ];
      let maxDate = 31;
      if (this.basicInfo.birth.year && this.basicInfo.birth.month) {
        maxDate = new Date(
          this.basicInfo.birth.year,
          this.basicInfo.birth.month,
          0
        ).getDate();
      }
      for (let i = 1; i <= maxDate; i++) {
        const text = `${i}`;
        allDate.push({ value: i, text });
      }

      return allDate;
    },
    datesLunar() {
      const allDate = [
        { text: this.$t("basic_info_field_select_date"), value: "-1" },
      ];
      let maxDate = 30;
      if (this.basicInfo.birth.lyear && this.basicInfo.birth.lmonth) {
        maxDate = solarLunar.monthDays(
          parseInt(this.basicInfo.birth.lyear),
          parseInt(this.basicInfo.birth.lmonth)
        );
      }
      for (let i = 1; i <= maxDate; i++) {
        const text = `${i}`;
        allDate.push({ value: i, text });
      }

      return allDate;
    },
    hours() {
      const allHours = [
        { text: this.$t("basic_info_field_select"), value: "-1" },
      ];
      allHours.push({
        value: 0,
        text: this.$t("basic_info_field_birth_hour_select_0") + "(00:00-01:00)",
      });
      allHours.push({
        value: 1,
        text: this.$t("basic_info_field_birth_hour_select_1") + "(01:00-03:00)",
      });
      allHours.push({
        value: 3,
        text: this.$t("basic_info_field_birth_hour_select_3") + "(03:00-05:00)",
      });
      allHours.push({
        value: 5,
        text: this.$t("basic_info_field_birth_hour_select_5") + "(05:00-07:00)",
      });
      allHours.push({
        value: 7,
        text: this.$t("basic_info_field_birth_hour_select_7") + "(07:00-09:00)",
      });
      allHours.push({
        value: 9,
        text: this.$t("basic_info_field_birth_hour_select_9") + "(09:00-11:00)",
      });
      allHours.push({
        value: 11,
        text:
          this.$t("basic_info_field_birth_hour_select_11") + "(11:00-13:00)",
      });
      allHours.push({
        value: 13,
        text:
          this.$t("basic_info_field_birth_hour_select_13") + "(13:00-15:00)",
      });
      allHours.push({
        value: 15,
        text:
          this.$t("basic_info_field_birth_hour_select_15") + "(15:00-17:00)",
      });
      allHours.push({
        value: 17,
        text:
          this.$t("basic_info_field_birth_hour_select_17") + "(17:00-19:00)",
      });
      allHours.push({
        value: 19,
        text:
          this.$t("basic_info_field_birth_hour_select_19") + "(19:00-21:00)",
      });
      allHours.push({
        value: 21,
        text:
          this.$t("basic_info_field_birth_hour_select_21") + "(21:00-23:00)",
      });
      allHours.push({
        value: 23,
        text:
          this.$t("basic_info_field_birth_hour_select_23") + "(23:00-00:00)",
      });
      allHours.push({ value: "NA", text: this.$t("basic_info_field_unknow") });
      return allHours;
    },
    constitutions() {
      const allConstitutions = [
        { text: this.$t("basic_info_field_select"), value: "-1" },
      ];
      allConstitutions.push({
        value: "C101",
        text: this.$t("basic_info_field_constitution_C101"),
      });
      allConstitutions.push({
        value: "C103",
        text: this.$t("basic_info_field_constitution_C103"),
      });
      allConstitutions.push({
        value: "C104",
        text: this.$t("basic_info_field_constitution_C104"),
      });
      allConstitutions.push({
        value: "C105",
        text: this.$t("basic_info_field_constitution_C105"),
      });
      allConstitutions.push({
        value: "C106",
        text: this.$t("basic_info_field_constitution_C106"),
      });
      allConstitutions.push({
        value: "C107",
        text: this.$t("basic_info_field_constitution_C107"),
      });
      allConstitutions.push({
        value: "C109",
        text: this.$t("basic_info_field_constitution_C109"),
      });
      allConstitutions.push({
        value: "C110",
        text: this.$t("basic_info_field_constitution_C110"),
      });
      return allConstitutions;
    },
    solarDate() {
      const basicInfo = this.basicInfo;
      if (
        basicInfo.birth.year != "-1" &&
        basicInfo.birth.month != "-1" &&
        basicInfo.birth.date != "-1"
      ) {
        return `${basicInfo.birth.year}-${basicInfo.birth.month}-${basicInfo.birth.date}`;
      } else {
        return this.$t("basic_info_field_auto_creat");
      }
    },
    lunarDate() {
      const basicInfo = this.basicInfo;
      if (
        basicInfo.birth.lyear != "-1" &&
        basicInfo.birth.lmonth != "-1" &&
        basicInfo.birth.ldate != "-1"
      ) {
        return `${basicInfo.birth.lyear}-${basicInfo.birth.lmonth}-${basicInfo.birth.ldate}`;
      } else {
        return this.$t("basic_info_field_auto_creat");
      }
    },
    enabledIsLeap() {
      const enabled =
        solarLunar.leapMonth(this.basicInfo.birth.lyear) ==
        this.basicInfo.birth.lmonth;
      if (!enabled) {
        this.basicInfo.birth.isLeap = [];
      }
      return enabled;
    },
  },
  methods: {
    goNext() {
      const checkResult = this.check();
      if (checkResult == true) {
        const basicInfo = Object.assign({}, this.basicInfo);
        const birth = `${basicInfo.birth.year}/${basicInfo.birth.month}/${basicInfo.birth.date}`;
        const lbirth = `${basicInfo.birth.lyear}/${basicInfo.birth.lmonth}/${basicInfo.birth.ldate}`;
        // const lunar = solarLunar.lunar2solar(
        //   parseInt(basicInfo.birth.lyear),
        //   parseInt(basicInfo.birth.lmonth),
        //   parseInt(basicInfo.birth.ldate)
        // );
        basicInfo.bHour = basicInfo.birth.hour;
        basicInfo.isleap = !!basicInfo.birth.isLeap[0];
        basicInfo.birth = birth;
        basicInfo.lbirth = lbirth;
        basicInfo.source = this.$route.params.source;
        if (basicInfo.constitution_code == "-1") {
          delete basicInfo.constitution_code;
        }
        loading();
        UserInfoApiHelper.submitBasicInfo(basicInfo)
          .then((info) => {
            loading(false);
            if (!basicInfo.constitution_code) {
              // 無填寫體質, 進入正常問券流程
              this.$router.replace({
                name: "Question",
              });
            } else {
              // 有填寫體質, 直接進入結果
              this.$router.replace({
                name: "Result",
              });
            }
          })
          .catch(errorHandler);
      } else {
        alert(checkResult);
      }
    },
    check() {
      let msgs = [];
      if (!this.basicInfo.name)
        msgs.push(this.$t("basic_info_field_name_message"));
      if (!this.basicInfo.is_overseas) {
        if (!this.basicInfo.id_number) {
          msgs.push(this.$t("basic_info_field_id_number_message"));
        } else if (!this.verifyId(this.basicInfo.id_number)) {
          msgs.push(this.$t("basic_info_field_id_number_error"));
        }
      }
      if (this.basicInfo.gender == -1)
        msgs.push(this.$t("basic_info_field_gender_message"));
      if (this.basicInfo.blood_type == "-1") {
        msgs.push(this.$t("basic_info_field_blood_type_message"));
      }
      if (
        !this.basicInfo.is_overseas &&
        this.basicInfo.id_number?.length == 10 &&
        this.basicInfo.gender != (this.basicInfo.id_number[1] == 1 ? 1 : 0)
      ) {
        msgs.push(this.$t("basic_info_field_gender_error"));
      }
      if (
        this.basicInfo.birth.year == "-1" ||
        this.basicInfo.birth.month == "-1" ||
        this.basicInfo.birth.date == "-1" ||
        this.basicInfo.birth.lyear == "-1" ||
        this.basicInfo.birth.lmonth == "-1" ||
        this.basicInfo.birth.ldate == "-1"
      ) {
        msgs.push(this.$t("basic_info_field_birth_date_message"));
      }
      if (this.basicInfo.birth.hour == "-1") {
        msgs.push(this.$t("basic_info_field_birth_hour_message"));
      }

      if (msgs.length > 0) {
        return msgs.join("\n");
      } else {
        return true;
      }
    },
    resetSolarDate() {
      const value = this.basicInfo.birth;
      // if (this.basicInfo.birth.hour == "-1") {
      //   alert(this.$t("basic_info_field_birth_hour_message"));
      //   return;
      // }
      if (value.lyear != "-1" && value.lmonth != "-1" && value.ldate != "-1") {
        const lunar = solarLunar.lunar2solar(
          parseInt(value.lyear),
          parseInt(value.lmonth),
          parseInt(value.ldate),
          !!value.isLeap[0]
        );
        let solarDate = new Date(lunar.cYear, lunar.cMonth - 1, lunar.cDay);
        // if (value.hour == 23) {
        //   solarDate = new Date(solarDate.getTime() - 24 * 60 * 60 * 1000);
        // }

        this.basicInfo.birth.year = String(solarDate.getFullYear() || "-1");
        this.basicInfo.birth.month = String(solarDate.getMonth() + 1 || "-1");
        this.basicInfo.birth.date = String(solarDate.getDate() || "-1");
      } else {
        alert(this.$t("basic_info_field_lunar_message"));
      }
    },
    resetLunarDate() {
      const value = this.basicInfo.birth;
      // if (this.basicInfo.birth.hour == "-1") {
      //   alert(this.$t("basic_info_field_birth_hour_message"));
      //   return;
      // }
      if (value.year != "-1" && value.month != "-1" && value.date != "-1") {
        let solarDate = new Date(value.year, value.month - 1, value.date);
        // if (value.hour == 23) {
        //   solarDate = new Date(solarDate.getTime() + 24 * 60 * 60 * 1000);
        // }
        const lunar = solarLunar.solar2lunar(
          solarDate.getFullYear(),
          solarDate.getMonth() + 1,
          solarDate.getDate()
        );
        this.basicInfo.birth.lyear = String(lunar.lYear || "-1");
        this.basicInfo.birth.lmonth = String(lunar.lMonth || "-1");
        this.basicInfo.birth.ldate = String(lunar.lDay || "-1");
        this.basicInfo.birth.isLeap = lunar.isLeap ? ["isLeap"] : [];
      } else {
        alert(this.$t("basic_info_field_solor_message"));
      }
    },
    setBasicInfo(basicInfo) {
      this.rawBasicInfo = copy(basicInfo);
      this.basicInfo.name = basicInfo.name || "";
      this.basicInfo.gender = basicInfo.gender || -1;
      this.basicInfo.blood_type = basicInfo.blood_type || "-1";
      this.basicInfo.email = basicInfo.email || "";
      this.basicInfo.birth.hour = String(basicInfo.bhour || -1);
      if (basicInfo.solar_birth) {
        const splitBirth = basicInfo.solar_birth.split("/");
        this.basicInfo.birth.year = splitBirth[0];
        this.basicInfo.birth.month = splitBirth[1];
        this.basicInfo.birth.date = splitBirth[2];
      }

      if (basicInfo.lunar_birth) {
        const splitLBirth = basicInfo.lunar_birth.split("/");
        this.basicInfo.birth.lyear = splitLBirth[0];
        this.basicInfo.birth.lmonth = splitLBirth[1];
        this.basicInfo.birth.ldate = splitLBirth[2];
      }
      this.basicInfo.trial = basicInfo.trial || false;
      this.basicInfo.is_overseas = basicInfo.is_overseas || false;
      this.basicInfo.id_number = basicInfo.id_number || "";
    },
    verifyId(id) {
      let _id = id.trim();

      const verification = id.match("^[A-Z][12]\\d{8}$");
      if (!verification) {
        return false;
      }

      let conver = "ABCDEFGHJKLMNPQRSTUVXYWZIO";
      let weights = [1, 9, 8, 7, 6, 5, 4, 3, 2, 1, 1];

      _id = String(conver.indexOf(_id[0]) + 10) + _id.slice(1);

      let checkSum = 0;
      for (let i = 0; i < _id.length; i++) {
        const num = parseInt(_id[i]);
        const weight = weights[i];
        checkSum += num * weight;
      }

      return checkSum % 10 == 0;
    },
  },
  watch: {
    "basicInfo.is_overseas"(value) {
      if (!value && this.basicInfo.id_number?.length > 10) {
        this.basicInfo.id_number = this.basicInfo.id_number.substr(0, 10);
      }
    },
    "$store.state.basicInfo"(basicInfo) {
      this.setBasicInfo(copy(basicInfo));
    },
  },
};
</script>
