<template>
  <!-- Vue起始框架 -->
  <div class="content colorMain">
    <LouHeader
      id="header"
      style="z-index: 1010; position: fixed; width: 100vw; top: 0"
      :menu="$router.currentRoute.meta.header.type == 'menu'"
      :back="$router.currentRoute.meta.header.type == 'back'"
      @menuClick="showMenu = !showMenu"
      @backClick="$router.go(-1)"
      :title="$router.currentRoute.meta.header.title"
    />
    <div
      v-if="showMenu"
      style="
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1000;
      "
      @click="showMenu = !showMenu"
    ></div>
    <Menu
      v-if="showMenu"
      theme="light"
      :open-names="currentMenu.map((menu) => menu.id)"
      :style="
        'position: fixed; z-index:1001; border: 1px solid gray; max-height:100%; max-height: calc(100% - 50px); overflow-y: auto;' +
        `top: ${headerHeight}px;`
      "
    >
      <Submenu
        v-for="(menu, index) in currentMenu"
        :key="index"
        :name="menu.id"
        class="menuGreen"
      >
        <template slot="title">
          <div style="display: flex; align-items: center">
            <Icon v-if="menu.icon" :type="menu.icon" />
            <div class="w-0 flex-grow-1 ml-2">{{ $t(menu.name) }}</div>
            <Icon class="myarrow" type="ios-arrow-forward" />
          </div>
        </template>
        <MenuItem
          v-for="child in menu.children"
          :name="child.id"
          @click.native="onMenuClick(child)"
          :key="child.id"
        >
          <div>
            {{ $t(child.name) }}
          </div>
        </MenuItem>
      </Submenu>
    </Menu>
    <div :style="`padding-top: ${this.headerHeight}px; width:100%`">
      <transition name="slide" mode="out-in" class="mx-0">
        <router-view
          class="container"
          @clocsMenu="showMenu = false"
        ></router-view>
      </transition>
    </div>
    <Spin size="large" fix v-show="showLoading" style="position: fixed"></Spin>
    <Modal v-model="dialog.show" :closable="false" :mask-closable="false">
      <pre
        style="text-align: left; white-space: pre-wrap; word-wrap: break-word"
        v-html="dialog.content"
      ></pre>
      <div slot="footer">
        <Button v-show="dialog.type == 'confirm'" @click="dialogNo">{{
          dialog.noText
        }}</Button>
        <Button @click="dialogYes">{{ dialog.yesText }}</Button>
      </div>
    </Modal>
  </div>
</template>
<script>
import UserInfoApiHelper from "../../script/Utility/UserInfoApiHelper";
import mainMenu from "../../menu/main.json";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getPerformance } from "firebase/performance";
const asyncHosts = ["foresee.louyiai.com.tw", "foresee.drlou.com.tw"];
let app = null;
export default {
  beforeCreate() {
    const source = this.$route.params.source;
    if (source) {
      const realSource = source.match(new RegExp(/^[a-zA-Z0-9]+/))[0];
      if (realSource != source) {
        this.$router.replace({
          name: this.$router.name,
          params: { source: realSource },
        });
      }
    }
    const push = this.$router.push.bind(this.$router);
    this.$router.push = (data) => {
      if (!compare(data, this.$router.currentRoute)) {
        push(data);
      }
    };
    window.alert = (content, yesCallback) => {
      this.setDailog(content, "alert", yesCallback, null);
    };
    window.toast = (content) => {
      this.$Message.success(content);
    };
    window.confirm = (content, yesCallback, noCallback) => {
      this.setDailog(content, "confirm", yesCallback, noCallback);
    };
    window.loading = (isShow = true) => {
      this.showLoading = isShow;
    };
    window.errorHandler = (...args) => {
      this.errorHandler(...args);
    };
    if (asyncHosts.indexOf(location.host) >= 0) {
      const firebaseConfig = {
        apiKey: "AIzaSyAeQZNDGnOYwFL8xxgLGV0uFoUH3Cij-z4",
        authDomain: "foresee-b9204.firebaseapp.com",
        projectId: "foresee-b9204",
        storageBucket: "foresee-b9204.appspot.com",
        messagingSenderId: "1007397443756",
        appId: "1:1007397443756:web:c12303d86f6bca19592709",
        measurementId: "G-41DBYCQ8FM",
      };
      // Initialize Firebase
      app = initializeApp(firebaseConfig);
      getAnalytics(app);
      getPerformance(app);
    }
  },
  mounted() {
    this.firebaseLogEvent(
      this.$router.currentRoute.name,
      this.$router.currentRoute.path
    );
    $("body").keydown(
      function (event) {
        if (event.which == 13) {
          if (this.dialog.show) {
            this.dialogYes();
          } else {
            this.$root.$emit("enter");
          }
        }
      }.bind(this)
    );
    this.$root.$on("closeMenu", this.closeMenu);
    this.checkData();
    window.onresize = () => {
      this.headerResize();
    };
    window.onresize();
    const source = this.$route.params.source;
    if (source) {
      loading();
      UserInfoApiHelper.getUserData({ source })
        .then((info) => {
          this.$store.commit("setBasicInfo", info);
          loading(false);
        })
        .catch(errorHandler);
    }
  },
  beforeDestroy() {
    this.$root.$off("closeMenu", this.closeMenu);
  },
  computed: {
    currentMenu() {
      return this.mainMenu.filter((menu) => {
        if (this.$route.meta.header.noShow instanceof Array) {
          if (this.$route.meta.header.noShow.find((no) => no == menu.id)) {
            return false;
          }
        }
        if (menu.needlogin) {
          return this.$store.state.basicInfo;
        }
        return true;
      });
    },
  },
  data() {
    return {
      headerHeight: 0,
      showMenu: false,
      mainMenu,
      isBlank: this.$router.currentRoute.meta.group == "blank",
      showLoading: false,
      defaultDialog: {
        show: false,
        content: "",
        yesText: this.$t("common_dialog_accept"),
        noText: this.$t("common_dialog_cancel"),
        yesCallback: null,
        noCallback: null,
      },
      dialog: {
        type: "",
        show: false,
        content: "",
        yesText: "",
        noText: "",
        yesCallback: null,
        noCallback: null,
      },
    };
  },
  methods: {
    firebaseLogEvent(name, path) {
      if (asyncHosts.indexOf(location.host) >= 0) {
        logEvent(getAnalytics(app), "page_view", {
          page_title: name,
          page_location: path,
        });
      }
    },
    closeMenu() {
      this.showMenu = false;
    },
    headerResize() {
      this.headerHeight = header.offsetHeight;
      this.minHeight = window.innerHeight - this.headerHeight;
    },
    checkData() {
      const to = this.$router.currentRoute;

      let backToHome = false;
      if (!this.isBlank && !to.params.source) {
        backToHome = true;
      }
      if (backToHome && to.name != "Home") {
        alert(this.$t("common_alert_system_busy"));
        this.$router.push({ name: "Home" });
      }
    },
    setDailog(content, type, yesCallback, noCallback) {
      Object.assign(this.dialog, this.defaultDialog);
      this.dialog.type = type;
      this.dialog.show = true;
      if (typeof content == "string") {
        this.dialog.content = content;
        this.dialog.yesCallback = yesCallback;
        this.dialog.noCallback = noCallback;
      } else if (typeof content == "object") {
        Object.assign(this.dialog, content);
      }
    },
    dialogYes() {
      this.dialog.show = false;
      if (typeof this.dialog.yesCallback == "function") {
        setTimeout(this.dialog.yesCallback, 100);
      }
    },
    dialogNo() {
      this.dialog.show = false;
      if (typeof this.dialog.noCallback == "function") {
        setTimeout(this.dialog.noCallback, 100);
      }
    },
    errorHandler(e) {
      loading(false);
      if (e.errorCode == 101999) {
        this.$store.commit("setBasicInfo", null);
        if (this.$route.name != "Home" && this.$route.name != "Verify") {
          // 邀請碼錯誤, 回邀請碼頁
          this.$router.push({ name: "Home" });
          toast(e.message);
        }
        return;
      } else if (e.errorCode == 100999) {
        this.$store.commit("setBasicInfo", null);
        if (this.$route.name != "Verify") {
          // 使用者登出, 回登入頁
          this.$router.push({ name: "Verify" });
          toast(e.message);
        }
        return;
      } else if (e.errorCode == 100998) {
        // 測驗次數不足, 回結果頁
        this.$router.push({ name: "Result" });
      }

      alert(e.message);
    },
    logout() {
      loading();
      UserInfoApiHelper.logout().catch(this.errorHandler);
    },
    onMenuClick(menuItem) {
      this.showMenu = false;
      if (menuItem.to) {
        this.$router.push({ name: menuItem.to });
      } else if (menuItem.onclick) {
        window.open(menuItem.onclick, "_blank");
      } else if (menuItem.id == "logout") {
        this.logout();
      }
    },
  },
  watch: {
    $route(to, from) {
      this.firebaseLogEvent(to.name, to.path);
      this.isBlank = to.meta.group == "blank";
      this.checkData();
    },
  },
};
</script>
<style scoped>
.slide-enter-active,
.slide-leave-active {
  transition: 0.3s;
}
.slide-leave-active {
  transition: 0.3s;
}
.slide-enter {
  transform: translateX(100%);
}
.slide-leave-to {
  transform: translateX(-100%);
}
.slide-enter-to,
.slide-leave {
  transform: translateX(0);
}
</style>