<template>
  <!-- 輸入啟動碼頁面 -->
  <div>
    <div class="py-5">
      <field-edit
        class="inputSolid"
        :field="$t('active_account_field_active_code')"
        v-model="active_code"
        :placeholder="$t('active_account_field_active_code_hint')"
        :maxlength="10"
        labelWidth="80px"
      />
    </div>
    <div class="button1" @click="submit()">
      {{ $t("common_button_commit") }}
    </div>
  </div>
</template>
<script>
import LoginApiHelper from "../../script/Utility/LoginApiHelper";
export default {
  mounted() {
    this.$root.$on("enter", this.submit);
    if (this.$route.query.activeCode) {
      this.active_code = this.$route.query.activeCode;
      this.submit();
    }
  },
  beforeDestroy() {
    this.$root.$off("enter", this.submit);
  },
  data() {
    return {
      active_code: "",
    };
  },
  methods: {
    submit() {
      if (this.active_code) {
        loading();
        LoginApiHelper.checkActiveCode(
          this.active_code,
          this.$route.params.source
        )
          .then((result) => {
            toast(this.$t("verify_alert_token_success"));
            loading(false);
            const basicInfo = result;
            this.$store.commit("setBasicInfo", basicInfo);
            if (basicInfo.records.length > 0) {
              this.$router.push({ name: "Result" });
            } else {
              this.$router.push({ name: "BeforeStart"});
            }
          })
          .catch(errorHandler);
      } else {
        alert(this.$t("active_account_field_active_code_hint"));
      }
    },
  },
};
</script>