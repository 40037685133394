<template>
  <!-- 編輯基本資料頁面 -->
  <div>
    <div style="position: relative; background-size: cover; position: relative">
      <div class="pt-3 bold">
        <field-edit
          class="my-4 inputSolid radius-5"
          :field="$t('edit_basic_info_field_name')"
          v-model="basicInfo.name"
          :placeholder="$t('edit_basic_info_field_name_hint')"
          :maxlength="15"
          :labelWidth="labelWidth"
          :required="true"
          name="name"
        />
        <div
          class="my-4"
          style="display: flex; flex-flow: wrap; justify-content: left"
        >
          <field-phone-number
            class="mr-1 mb-1"
            :field="$t('edit_basic_info_field_mobile')"
            v-model="basicInfo.mobile"
            :labelWidth="labelWidth"
            :required="true"
            style="flex-grow: 1"
          />
          <div
            class="button2"
            style="margin: 0 0 0 auto"
            @click="sendToken()"
            :disabled="!canSendMobile"
          >
            {{ sendTokenText }}
          </div>
        </div>
        <field-edit
          v-show="sent && modifiedMobile"
          v-model="token"
          class="inputSolid radius-5"
          :field="$t('edit_basic_info_field_token')"
          :placeholder="$t('edit_basic_info_field_token_hint')"
          :maxlength="6"
          :labelWidth="labelWidth"
          :required="true"
        />
        <field-edit
          class="my-4 inputSolid radius-5"
          field="E-Mail"
          v-model="basicInfo.email"
          placeholder="service@louyiai.com"
          :maxlength="50"
          :labelWidth="labelWidth"
          name="email"
        />
      </div>
      <div v-if="modified" class="button1" @click="goNext()">
        {{ $t("common_button_commit") }}
      </div>
    </div>
  </div>
</template>
<script>
import LoginApiHelper from "../../script/Utility/LoginApiHelper";
import UserInfoApiHelper from "../../script/Utility/UserInfoApiHelper";
import FieldPhoneNumber from "../component/FieldPhoneNumber.vue";
export default {
  components: { FieldPhoneNumber },
  mounted() {
    this.$root.$on("enter", this.goNext);
    const basicInfo = this.$store.state.basicInfo;
    if (basicInfo) {
      this.setBasicInfo(copy(basicInfo));
    }
  },
  beforeDestroy() {
    this.$root.$off("enter", this.goNext);
  },
  data() {
    const source = this.$route.params.source;
    const basicInfo = this.$store.state.basicInfo;
    return {
      timeLeft: 0,
      waitTime: 60,
      sent: false,
      modifiedMobile: false,
      modified: false,
      labelWidth: "100px",
      originBasicInfo: null,
      basicInfo: {
        email: basicInfo?.email || "",
        mobile: {
          mobile_country_code: basicInfo?.mobile_country_code || "",
          mobile: basicInfo?.mobile || "",
          success: true,
        },
        name: basicInfo?.name || "",
        source,
      },
      token: "",
    };
  },
  computed: {
    sendTokenText() {
      return this.timeLeft
        ? `${this.timeLeft} s`
        : this.$t("edit_basic_info_button_token_send");
    },
    canSendMobile() {
      return (
        this.modifiedMobile &&
        this.timeLeft == 0 &&
        this.basicInfo.mobile.success
      );
    },
  },
  methods: {
    goNext() {
      const checkResult = this.check();
      if (checkResult == true) {
        const source = this.$route.params.source;
        const basicInfo = Object.assign(
          { verify_code: this.token, source },
          this.basicInfo
        );
        basicInfo.mobile_country_code = basicInfo.mobile.mobile_country_code;
        basicInfo.mobile = basicInfo.mobile.mobile;
        loading();
        UserInfoApiHelper.modifyBasicInfo(basicInfo)
          .then((info) => {
            loading(false);
            this.$store.commit("setBasicInfo", info);
            this.setBasicInfo(copy(info));
            this.$router.go(-1);
            alert(this.$t("edit_basic_info_alert_modified_success"));
          })
          .catch(errorHandler);
      } else {
        alert(checkResult);
      }
    },
    check() {
      let msgs = [];
      if (!this.basicInfo.name)
        msgs.push(this.$t("basic_info_field_name_message"));
      if (this.modifiedMobile && !this.token)
        msgs.push(this.$t("edit_basic_info_field_mobile_verify_hint"));
      if (msgs.length > 0) {
        return msgs.join("\n");
      } else {
        return true;
      }
    },
    setBasicInfo(basicInfo) {
      if (basicInfo.mobile_country_code) {
        basicInfo.mobile = {
          mobile_country_code: basicInfo.mobile_country_code,
          mobile: basicInfo.mobile,
          success: true,
        };
        delete basicInfo.mobile_country_code;
      }
      this.originBasicInfo = Object.assign({}, basicInfo);
      this.basicInfo = Object.assign(this.basicInfo, basicInfo);
    },
    sendToken() {
      if (!this.basicInfo.mobile.success) {
        alert(this.$t("edit_basic_info_field_mobile_format_error"));
        return;
      }
      if (this.timeLeft == 0) {
        const params = {
          mobile: this.basicInfo.mobile.mobile,
          mobile_country_code: this.basicInfo.mobile.mobile_country_code,
          source: this.basicInfo.source,
        };
        LoginApiHelper.sendVerifyMobile(params).catch(errorHandler);
        const startTime = new Date();
        const intervel = setInterval(() => {
          const timeLeft = Math.floor(
            this.waitTime - (new Date() - startTime) / 1000
          );
          if (timeLeft > 0) {
            this.timeLeft = timeLeft;
          } else {
            this.timeLeft = 0;
            clearInterval(intervel);
          }
        }, 300);
        this.sent = true;
      }
    },
  },
  watch: {
    basicInfo: {
      handler(value) {
        if (this.originBasicInfo) {
          this.modifiedMobile = !compare(
            value.mobile,
            this.originBasicInfo?.mobile
          );
          if (!compare(value, this.originBasicInfo)) {
            this.modified = true;
          } else {
            this.modified = false;
          }
        } else {
          this.modified = false;
        }
      },
      deep: true,
    },
    "$store.state.basicInfo"(basicInfo) {
      this.setBasicInfo(copy(basicInfo));
    },
  },
};
</script>
<style scoped>
.p1 {
  padding: 1px;
}
</style>